.login-list {
    display: inline-flex;
    list-style: none;
}

.login-list li a {
    padding: .5rem;
    text-decoration: none;
    color: var(--secondary-main)
}
.login-list li a:hover {
    background-color: var(--primary-main);
    color: #ffffff;
}