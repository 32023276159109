html, body, #root {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.App {
    flex: 1;
    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.menu-item{
    color: #ffffff !important;
    border: none;
    border-radius: 0%;
    padding: .5rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 250ms linear;
}

.menu-item:hover {
    background-color: #082B3D !important;
}
.menu-item.active:hover {
    background-color: #b75b1a !important;
}