@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

:root {
  --primary-dark: #082b3d;
  --primary-main: #0f3c53;
  --accent-main: #b75b1a;
  --accent-light: #7e3c0f;
  --secondary-main: #0066cc;
  --secondary-dark: #0d2f51;
}
html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans", sans-serif;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.styled-table thead tr {
  background-color: #0f3c53;
  color: #ffffff;
  text-align: left;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #0066cc;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #0066cc;
}
.styled-table td {
  padding-left: 1em;
}
/* Float four columns side by side */
.styled-card-column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.styled-card-row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.styled-card-row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .styled-card-column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.styled-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}
